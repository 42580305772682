/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider useRecaptchaNet reCaptchaKey="6LcYnpkaAAAAAMu0uTl7OyCCYgBKGYoLNpUdc5eR">
            {element}
        </GoogleReCaptchaProvider>
    )
}

const $ = require("jquery")


export const onInitialClientRender = () => {

    $(document).ready(function () {
        window.funciones()

        if ($('#suscribir-form').length) {

            var newsletter_form = $('#suscribir-form');

            newsletter_form.on('submit', function (e) {
                e.preventDefault();
                confirmAlert({
                    customUI: ({ onClose }) => {
                        return (
                            <div className='custom-ui'>
                                <p>Gracias por suscribirse al boletin de noticias.</p>

                                <button
                                    onClick={() => {
                                        onClose();
                                    }}
                                >
                                    Aceptar
                                </button>
                            </div>
                        );
                    }
                });
            });

        }
    });
}
export const onRouteUpdate = ({ location }) => {
    checkHash(location)
  }
const checkHash = location => {
    let { hash } = location
    if (hash.includes('#')) {
      let [_,anchor] =  hash.split('#')
      let [clearAnchor,params] = anchor.split("?")
      if(clearAnchor&&clearAnchor!=undefined){
        let location = document.getElementById(clearAnchor);    
        if(location){
            console.log("LOCATION ;;",location.offsetTop)
            setTimeout(function(){

                $('html, body').stop().animate({scrollTop: getLocation(clearAnchor)},100);

            }, 200);
            
        }
    }
      if(params){
        let paramsArray = params.split("&") 
        let paramDict = Object.assign({}, ...paramsArray.map((x)=>({[x.split("=")[0]]:x.split("=")[1]})))
        
        if('activate' in paramDict){
            $("."+clearAnchor+" .active").removeClass("active");
            $("."+clearAnchor+" .show").removeClass("show");
            $("."+clearAnchor+" ."+paramDict.activate).addClass("active");
            $("."+clearAnchor+" ."+paramDict.activate+"_content").addClass("in active show");
        }
      }
      console.log("HASH EN URL")
    }
  }

  function getLocation(idelement){
    let location = document.getElementById(idelement);    
    console.log("location method",location.offsetTop);
    return location.offsetTop-50

  }