// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-contacto-js": () => import("./../../../src/pages/en/contacto.js" /* webpackChunkName: "component---src-pages-en-contacto-js" */),
  "component---src-pages-en-gestion-js": () => import("./../../../src/pages/en/gestion.js" /* webpackChunkName: "component---src-pages-en-gestion-js" */),
  "component---src-pages-en-js": () => import("./../../../src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-en-mapa-sitio-js": () => import("./../../../src/pages/en/mapa-sitio.js" /* webpackChunkName: "component---src-pages-en-mapa-sitio-js" */),
  "component---src-pages-en-nosotros-js": () => import("./../../../src/pages/en/nosotros.js" /* webpackChunkName: "component---src-pages-en-nosotros-js" */),
  "component---src-pages-en-politica-privacidad-js": () => import("./../../../src/pages/en/politica-privacidad.js" /* webpackChunkName: "component---src-pages-en-politica-privacidad-js" */),
  "component---src-pages-en-search-js": () => import("./../../../src/pages/en/search.js" /* webpackChunkName: "component---src-pages-en-search-js" */),
  "component---src-pages-en-sectores-js": () => import("./../../../src/pages/en/sectores.js" /* webpackChunkName: "component---src-pages-en-sectores-js" */),
  "component---src-pages-en-servicios-js": () => import("./../../../src/pages/en/servicios.js" /* webpackChunkName: "component---src-pages-en-servicios-js" */),
  "component---src-pages-en-terminos-condiciones-js": () => import("./../../../src/pages/en/terminos-condiciones.js" /* webpackChunkName: "component---src-pages-en-terminos-condiciones-js" */),
  "component---src-pages-es-contacto-js": () => import("./../../../src/pages/es/contacto.js" /* webpackChunkName: "component---src-pages-es-contacto-js" */),
  "component---src-pages-es-gestion-js": () => import("./../../../src/pages/es/gestion.js" /* webpackChunkName: "component---src-pages-es-gestion-js" */),
  "component---src-pages-es-mapa-sitio-js": () => import("./../../../src/pages/es/mapa-sitio.js" /* webpackChunkName: "component---src-pages-es-mapa-sitio-js" */),
  "component---src-pages-es-nosotros-js": () => import("./../../../src/pages/es/nosotros.js" /* webpackChunkName: "component---src-pages-es-nosotros-js" */),
  "component---src-pages-es-politica-privacidad-js": () => import("./../../../src/pages/es/politica-privacidad.js" /* webpackChunkName: "component---src-pages-es-politica-privacidad-js" */),
  "component---src-pages-es-search-js": () => import("./../../../src/pages/es/search.js" /* webpackChunkName: "component---src-pages-es-search-js" */),
  "component---src-pages-es-sectores-js": () => import("./../../../src/pages/es/sectores.js" /* webpackChunkName: "component---src-pages-es-sectores-js" */),
  "component---src-pages-es-servicios-js": () => import("./../../../src/pages/es/servicios.js" /* webpackChunkName: "component---src-pages-es-servicios-js" */),
  "component---src-pages-es-terminos-condiciones-js": () => import("./../../../src/pages/es/terminos-condiciones.js" /* webpackChunkName: "component---src-pages-es-terminos-condiciones-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

